import $ from 'jquery';

export async function MobileIndex() {
    if (window.innerWidth < 767) {
        await resize();
    }
}

async function resize() {
    let center1 = document.createElement("center");
    center1.id = "center1";
    $(center1).appendTo('#mobile');
    let br = document.createElement("br");
    let url = window.location.pathname;
    if (url === "/") {
        resizeBlog(center1, br);
    } else if (url.includes("/blog/")) {
        resizeBlogPost(center1, br);
    }

    document.getElementById("center1").appendChild(br.cloneNode(true));
    document.getElementById("center1").appendChild(br.cloneNode(true));
    document.getElementById("center1").appendChild(br.cloneNode(true));

    let footer = document.createElement("div");
    footer.style.background = "black";
    footer.style.padding = "10%";
    $(footer).appendTo(center1);
    let centerfooter = document.createElement("center");
    $(centerfooter).appendTo(footer);
    $("#join").appendTo(centerfooter);
    $(br.cloneNode(true)).appendTo(centerfooter);
    $("#download").appendTo(centerfooter);

    $(br.cloneNode(true)).appendTo(centerfooter);
    $("#footerleft").appendTo(centerfooter);
    document.getElementById("joinbtn").className = "vertcenter";
    document.getElementById("downloadbtn").className = "vertcenter";

    document.getElementById("footerleft").style.width = "100%";

}
function resizeBlogPost(center1, br) {
    $('#blogpost').appendTo(center1);
    document.getElementById("blogpost").style.margin = "2.5%";
    document.getElementById("posttitle").style.fontSize = "200%";


}

function resizeBlog(center1, br) {
    $('#blogdiv').appendTo(center1);
    document.getElementById("blogdiv").style.margin = "0%";
    document.getElementById("featuredbg").style.visibility = "hidden";
    document.getElementById("featuredtitle").style.fontSize = "150%";
    document.getElementById("featureddate").className = "vertcenter";
    let tabsdiv = document.getElementsByClassName("tabsdiv");
    for (let i = 0; i < tabsdiv.length; i++) {
        document.getElementsByClassName("tabsdiv")[i].style.display = "block";
    }
    let tabs = document.getElementsByClassName("tabs");
    console.log(tabs);
    for (let i = 0; i < tabs.length; i++) {
        document.getElementsByClassName("tabs")[i].style.width = "100%";
        document.getElementsByClassName("tabs")[i].style.marginTop = "10%";

    }
}

export async function SidebarJS() {
    sidebarResize();
}

async function sidebarResize() {
    if (window.innerWidth < 767) {
        let sidebar = document.createElement("div");
        sidebar.id = "mySidenav";
        sidebar.className = "sidenav";
        sidebar.appendChild(document.getElementById("menusidebar"));

        $(sidebar).appendTo('#mobile');
        document.getElementById("openbtn").addEventListener("click", async function () {
            openNav()
        })
        document.getElementById("closebtn").style.display = "block";
        document.getElementById("closebtn").addEventListener("click", async function () {
            closeNav()
        })
    }
}

function openNav() {
    document.getElementById("mySidenav").style.marginLeft = "0%";

}

function closeNav() {
    document.getElementById("mySidenav").style.marginLeft = "-100%";
}