import React from "react";
// import { Docs } from "../../../../1.resources/2.js/1.mainpages/docs/docs"
import OneImg from "../../../1.resources/3.images/images/blogs/1alt.png";
import TwoImg from "../../../1.resources/3.images/images/blogs/twitter/2.png";

import OneImgBG from "../../../1.resources/3.images/images/blogs/featuredbg.png";
import "../../../1.resources/1.css/1.mainpages/blog.css";

class DevMain extends React.Component {

    async componentDidMount() {
        // Docs();
        //document.getElementById("blogdiv").style.minHeight = (window.innerHeight) + "px";
    }

    render() {
        return (
            <div>
                <div style={{ background: "#fff", padding: "5%" }}></div>
                <div id="blogdiv" style={{
                    marginLeft: "10%", marginRight: "10%", marginTop: "-7.5%", background: "#fff",
                    borderRadius: "12.5px", padding: "5%", paddingTop: "1.5%"
                }}>
                    <div style={{ marginBottom: "5%" }} >
                        <center>
                            <p style={{ fontSize: "250%", fontWeight: "bold" }}>BLOG</p>
                            <p style={{ color: "#7b3fe4", fontSize: "80%" }}>Updates from Web3 Name Service (WNS)</p>
                        </center>
                    </div>
                    <center><p style={{ fontWeight: "bold", marginBottom: "1.5%" }}>Featured Post</p></center>
                    <Featured title="Let's begin with Web3"
                        subheading="Blog / Welcome" date="March 11, 2022"
                        body="Web3 was one of the most frequently searched
                        terms in 2021, although it doesn’t have a formal definition,
                        but simply put, it refers to the third generation of the internet..."
                        link="/blog/let's-begin-with-web3" />


                    <center><p style={{ fontWeight: "bold", marginBottom: "1.5%" }}>Latest Posts</p></center>
                    <div className="tabsdiv" style={{ display: "flex" }}>
                        <Tabs title="How IPFS will power the Web3 e-mail"
                            subheading="Blog / W3Mail" date="March 20, 2022"
                            img={TwoImg} link="/blog/how-ipfs-will-power-the-web3-email"
                        />

                        <Tabs title="Let's begin with Web3"
                            subheading="Blog / Welcome" date="March 11, 2022"
                            img={OneImg} link="/blog/let's-begin-with-web3"
                        />


                    </div>

                    <br />
                </div>
                <br />
            </div >

        )
    }
}

const Featured = (props) => {
    return (
        <div>
            <div style={{}}>
                <div id="featuredbg" style={{ background: "#7b3fe4", padding: "10%", borderRadius: "12.5px", width: "100%" }}></div>
                <div style={{
                    background: "white", marginLeft: "5%", marginRight: "5%", padding: "5%", paddingTop: "2.5%", paddingBottom: "3.5%",
                    marginTop: "-15%", borderRadius: "12.5px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                }}>
                    <p style={{ fontSize: "70%", color: "#7b3fe4", fontWeight: "bold", marginTop: "2.5%", marginBottom: "0%" }}>{props.subheading}</p>
                    <a id="featuredtitle" href={props.link} style={{ fontSize: "200%", fontWeight: "bold", color: "#000", cursor: "pointer" }}>{props.title}</a>
                    <div id="featureddate" style={{ display: "flex", fontWeight: "bold", marginTop: "1%" }} className="onlyvertcenter">
                        <p style={{ fontSize: "70%", color: "#606060" }}>{props.date}</p>
                        <span className="dot" style={{ marginLeft: "1.5%", marginRight: "1.5%" }}></span>
                        <p style={{ fontSize: "70%", color: "#7b3fe4" }}>WNS Team</p>
                    </div>
                    <p style={{ color: "#606060", fontSize: "80%", marginTop: "1.5%" }}>{props.body}
                        <a href={props.link} style={{ cursor: "pointer" }} className="bluelink">Read More</a></p>

                </div>
            </div>
            <br /><br /><br />
        </div>
    )
}

const Tabs = (props) => {
    return (

        <div className="tabs" style={{}}>
            <a href={props.link} style={{ cursor: "pointer", color: "#000" }}>
                <img src={props.img} style={{ borderRadius: "7px" }}></img>
                <div style={{ padding: "5%", paddingLeft: "7.5%", paddingRight: "7.5%" }}>
                    <p style={{ fontSize: "70%", color: "#7b3fe4", fontWeight: "bold", marginTop: "2.5%", marginBottom: "0%" }}>{props.subheading}</p>
                    <p style={{ fontSize: "125%", fontWeight: "bold" }}>{props.title}</p>
                    <div style={{ display: "flex", fontWeight: "bold", marginTop: "2%" }} className="onlyvertcenter">
                        <p style={{ fontSize: "70%", color: "#606060" }}>{props.date}</p>
                        <span className="dot" style={{ marginLeft: "2.5%", marginRight: "2.5%" }}></span>
                        <p style={{ fontSize: "70%", color: "#7b3fe4" }}>WNS Team</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default DevMain;
