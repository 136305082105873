import React from "react";
// import { Docs } from "../../../../1.resources/2.js/1.mainpages/docs/docs"

import StoreImg from "../../../../1.resources/3.images/images/blogs/2/store.png";
import MerkleImg from "../../../../1.resources/3.images/images/blogs/2/merkle2.png";

import Meta from "./partials/meta";
import WriterBar from "./partials/writerbar";
class DevMain extends React.Component {

    async componentDidMount() {

    }

    render() {
        let title = "How IPFS will power the Web3 email";
        let des = "In the simplest terms, Web3 is an effort to decentralize the current technologies that enable the Internet as we know it. Decentralization as a concept is critical when considering the shortcomings of a centralized system. Security and privacy are two critical areas in which the Web3 revolution seeks to make..."
        let img = "https://raw.githubusercontent.com/web3nameservice/wns-resources/main/images/blog/2.png";
        return (
            <div>
                <Meta title={title} des={des} img={img} />
                <div>
                    <p style={{ fontSize: "80%" }}>
                        <a href="/" className="bluelink">Blog</a> / <a href="/blog/let's-begin-with-web3" className="bluelink">W3Mail</a>
                    </p>
                </div>
                <div >
                    <p id="posttitle" style={{ fontSize: "250%", fontWeight: "bold" }}>How IPFS will power the Web3 email</p>
                </div>

                <br />
                <WriterBar date="March 20, 2022" />

                <div>
                    {/* <img src={OneImg} /> */}
                    <p id="postpara" className="docs-paragraph" style={{ textAlign: "left" }}>In the simplest terms, Web3 is an effort to decentralize
                        the current technologies that enable the Internet as we know it. Decentralization as a concept is critical when considering the
                        shortcomings of a centralized system. Security and privacy are two critical areas in which the Web3 revolution seeks to make a
                        difference. When attempting to design W3Mail, an emailing service for Web3, the WNS team intended to address these critical
                        concerns.
                        <br /><br />
                        The email services provided by Web2 are not as secure
                        as they should be. Every other day, we hear about email leaks that are used to publicize a conversation that was meant to be private
                        in the first place. To develop a Web3 email service on the Web3 revolution's fundamental ideals, it must overcome this security
                        flaw that Web2 email services often encounter. There should be no single point of failure in the system. Private communication
                        between two individuals should remain secret and unheard by a third party.
                        <br /><br />
                        While the concept of security and privacy in terms of
                        information flow is straightforward, its execution presents many significant obstacles. Attempts have been made in the past to
                        make this vision a reality via the use of Blockchain technology. While these efforts to create an emailing service have made
                        significant strides toward attaining this ideal, there has been one factor that has made its global acceptance less likely:
                        gas fees.
                        <br /><br />
                        If we attempt to recreate email on the Blockchain,
                        there will be a fee associated with sending an email from one party to another. And although this cost may be negligible for
                        a single such email, the issue becomes massive when applied to a more regular use case, such as a chat service. Even if a
                        party is ready to pay a price for sending an email, they are unlikely to pay costs for each chat message sent to another party.
                        <br /><br />
                        To address this issue, we turned to another Web3 technology that offers a distributed network for storage without any gas fees associated with it: the Interplanetary File System (IPFS). By using IPFS as the storage layer and Merkle Proofs as the management mechanism, we can create an email service that is incomparably more secure and private than Web2 alternatives.
                        <br /><br />
                        To understand how it works, we must first understand
                        some key concepts:
                        <br /><br />

                        <p style={{ padding: "5%", borderLeft: "4px solid #7b3fe4", background: "#ede6fa" }}>
                            <b style={{ fontSize: "150%" }}>InterPlanetary File System (IPFS)</b>
                            <br /><br />
                            {/* <img src="https://www.coywolf.news/wp-content/uploads/2021/04/ipfs-logo.webp" style={{ width: "50%" }}></img>
                            <br /><br /> */}
                            Let's just start with a one-line definition of IPFS:
                            <br /><br />
                            <b>IPFS is a distributed system for storing and accessing files, websites, applications, and data.</b>
                            <br /><br />
                            What does that mean, exactly? IPFS is an implementation of a decentralized network. One of the most popular
                            decentralized systems is Git, the version control software. Git is a distributed system because every developer
                            who has cloned a repository has a copy of the entire repository, including the history, on their computer. If the
                            central repository is wiped out, any copy of the repository can be used to restore it. IPFS takes that distributed concept
                            and applies it to file storage and data retrieval.
                            <br /> <br />


                            IPFS was created by Juan Benet and is maintained by Protocol Labs, the company he founded.
                            They took the decentralized nature of Git and the distributed, bandwidth-saving techniques
                            of torrents and created a filing system that works across all of the nodes in the IPFS network.
                            <br /><br />
                            <img style={{ width: "100%" }} src="https://preview.redd.it/sqshr2wui6t71.jpg?width=701&format=pjpg&auto=webp&s=4b2b99b63e1898a0a118c797f0bbceeddac358ca"></img>
                            <br /> <br />
                            The IPFS decentralized web is made up of all the computers connected to it, known as nodes. Nodes can store data and make it accessible to anyone who requests it.
                            If someone requests a file or a webpage, a copy of the file is cached on their node. As more and more people request that data, more and more cached copies will exist. Subsequent requests for that file can be fulfilled by any node—or combination of nodes—that has the file on it. The burden of delivering the data and fulfilling the request is gradually shared out amongst many nodes.
                            This calls for a new type of web address. Instead of address-based routing where you have to know the location of the data and provide a specific URL to that data, the decentralized web uses content-based routing.

                            <br /><br />
                            You don’t say where the data is; you request what you want, and it is found and retrieved for you. Because the data is stored on many different computers, all of those computers can feed parts of the data to your computer at once, like a torrent download. This is intended to lower latency, reduce bandwidth, and avoid bottlenecks caused by a single, central, server.
                            <br /><br />
                            <span style={{ fontSize: "80%" }}>Reference: <a href="https://www.howtogeek.com/784295/what-is-the-interplanetary-file-system-ipfs/" target="_blank" className="bluelink">Dave McKay</a></span>

                        </p>
                        <br /><br />
                        <p style={{ padding: "5%", borderLeft: "4px solid #7b3fe4", background: "#ede6fa" }}>
                            <b style={{ fontSize: "150%" }}>Merkle Trees</b>
                            <br /><br />
                            A Merkle tree allows for the verification of large volumes of data while maintaining the integrity of the data without taking up large amounts of space on a network and with minimal computing power.
                            <br /><br />
                            In simple terms, the beauty of the Merkle tree is its ability to take large amounts of input data and compress it into an output of fixed length. This output consists of just a single string of characters called a Merkle root. The Merkle root can easily act as proof of the validity of the data that it represents. It can also easily be shared with others on the network. This means that copies of the entire database do not need to be maintained by each and every user. If an error occurs, we do not need to do a line-by-line search of the entire database.
                            <br /><br />
                            However, before we can understand how a Merkle tree works, we need to understand the basics of hash functions. This is because hash functions are the key technology underlying a Merkle tree.
                            <br /><br />
                            <b>Hash Functions</b>
                            <br /><br />
                            Cryptographic hash functions transform text or binary data to fixed-length hash value and are known to be collision-resistant and irreversible. Example of cryptographic hash function is SHA3-256:
                            <br /><br />
                            <img src="https://blog.sigmadex.org/content/images/size/w1000/2021/02/Hash1.png"></img>
                            <img src="https://blog.sigmadex.org/content/images/size/w1000/2021/02/Hash2.png"></img>
                            <br /><br />
                            Notice what happens if only change one minor detail of the transaction and apply the same hash function. Our output is completely different.
                            The resulting hash value is unique to the input data and is like a fingerprint of the data. The two output strings are very different despite having just a small change in the input data. Therefore, it is easy to determine when a file is corrupted.
                            <br /><br />
                            The key technology underlying a Merkle tree is the hash function, which serves the dual purpose of both encrypting the data and compressing it into a manageable size. The basic structure of a Merkle tree is like that of a reverse tree as shown in the following diagram:
                            <br /><br />
                            <img src="https://blog.sigmadex.org/content/images/size/w1000/2021/02/MerkleRootDiagram.png"></img>
                            <br /><br />
                            As with any tree, there are leaves, branches, and a root. The leaves are the transactions (or blocks of transactions) that make up the input data. A hash function is applied to each block of transactions (T1, T2, T3, T4) to create what are called leaf nodes (W, X, Y, Z). The leaf nodes are the start of the Merkle tree.
                            <br /><br />
                            In this example, we can concatenate (join together) two leaf nodes (W+X) to create a parent node(A) by applying the hash function again. We can do the same with leaf nodes (Y+Z) to create a parent node (B). We can then hash parent nodes A+B together to get what is called the Merkle Root. The result is an output made up of a string of characters with a fixed length. Now instead of storing a large set of data, we can represent that entire dataset with just the Merkle root.
                            <br /><br />
                            <span style={{ fontSize: "80%" }}>Reference: <a href="https://blog.sigmadex.org/merkle-tree-explained/" target="_blank" className="bluelink">Shane Moser</a></span>

                        </p>
                        <br /><br />
                        <b style={{ fontSize: "150%" }}>How W3Mail works</b>
                        <br /><br />
                        Now that we've covered the fundamental technologies required to develop W3 Mail, let's look at how an email from person A travels to person B to illustrate how it works:
                        <br /><br />
                        <img style={{ width: "100%" }} src={StoreImg}></img>
                        <br /><br />
                        <b style={{ fontSize: "125%" }}>1.Your Wallet Address (WNS username)</b>
                        <p style={{ marginTop: "2.5%" }}>
                            This is where it all begins. Each wallet address is comprised of three critical components: an Address, a Public Key, and a Private Key. Your address is the lengthy address with which you interact with everything (in the case of Ethereum, it starts with 0x). Your Public Key is your key that is visible to everyone, as the name implies. On the other hand, your Private Key is something that is confidential and visible only to the address's owner. Due to the fact that the majority of wallet providers now keep Private Keys secret and do not reveal them, when person A joins up for the email service, a whole new wallet is created for him that is directly connected to his personal wallet address/WNS username. The private keys for the W3Mail wallet are encrypted with a password that only person A knows and kept on IPFS to guarantee that no third party, not even W3Mail, has access to them.
                        </p>
                        <br /><br />
                        <b style={{ fontSize: "125%" }}>2. Hashing the e-mail</b>
                        <p style={{ marginTop: "2.5%" }}>
                            When person A sends an email to person B, the email is hashed using the public key of person B's W3Mail wallet, which can subsequently be decrypted using Person B's private key, which is stored in encrypted form on IPFS and can be decrypted using a password known only to person B.
                        </p>
                        <br /><br />
                        <b style={{ fontSize: "125%" }}>3. Storing the email on IPFS</b>
                        <img style={{ width: "100%" }} src={MerkleImg}></img>
                        <br /><br />
                        <p style={{ marginTop: "2.5%" }}>
                            The Merkle Tree is used to store all emails on IPFS. The tree's root layer contains all emails. They are connected to a higher layer that stores all W3Mail addresses and is connected to a root W3Mail directory. Each time an email is sent, a new email hash is added to the base layer, which modifies the hash of the address layer associated with that email, which modifies the hash of the W3Mail directory. As a result, the root directory of W3Mail changes with each new email. To maintain anonymity, even the wallet addresses are encrypted so that anybody reading the root directory would be unable to determine which W3Mail address leaf corresponds to which user.
                        </p>

                        <br /><br />
                        <b style={{ fontSize: "125%" }}>4. Retrieving the e-mail</b>
                        <p style={{ marginTop: "2.5%" }}>
                            When it comes time to retrieve emails, W3Mail's root directory is accessed, which refers to the user directory, which contains all of the emails of that particular user. After retrieving all emails, person B decrypts the private key for his W3Mail address using a password that only he knows. And using the private key, all the emails are decrypted and shown to person B.
                        </p>

                        <br /><br />
                        Thus, an email is delivered from person A to person B fully securely and discreetly, with no third party in between able to see the contents of the email. Multiple layers of encryption throughout the email system guarantee that there is no single point of failure. Because all emails are kept on IPFS, they are publicly accessible; however, they must be decrypted numerous times in order to be viewed.
                        <br /><br />
                        W3Mail will be released to the public in Beta shortly, and the WNS team is hard at work implementing this technology across a variety of different routes for secure information exchange including a mobile-friendly chat application and other social media platforms.
                        <br /><br />
                        To stay in the loop on the release, follow Web3 Name Service's Twitter page: <a href="https://twitter.com/wnsdomains" target="_blank" className="bluelink">@wnsdomains</a>

                    </p>


                </div>
            </div >

        )
    }
}

export default DevMain;
