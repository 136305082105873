import React from "react";
import HeaderLogo from "./headerlogo";


function Header() {
    function scrollTo(el) {
        document.getElementById(el + "div").scrollIntoView();
    }
    return (
        <div id="headerbar">
            <div id="desktopheader">
                <div style={{ padding: "0.5%", paddingLeft: "7.5%", paddingRight: "7.5%", display: "flex" }} className="onlyvertcenter">
                    <div style={{ width: "20%", display: "flex" }}>
                        <a id="logo" href="https://wnsdomains.io" style={{ cursor: "pointer" }}><HeaderLogo /></a>
                    </div>
                    <div id="links" style={{ width: "60%" }} className="onlyvertcenter">
                        <a href="https://wnsdomains.io/" style={{ marginRight: "2%", color: "black", fontSize: "85%", cursor: "pointer" }}>Home</a>

                        <a href="https://wnsdomains.io/app" style={{ marginRight: "2%", color: "black", fontSize: "85%", cursor: "pointer" }}>Register</a>
                        <a target="_blank" href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd"
                            style={{ marginRight: "2%", color: "black", fontSize: "85%", cursor: "pointer" }}>Extension</a>
                        <a href="/" style={{ marginRight: "2%", color: "black", fontSize: "85%", cursor: "pointer" }}>Blog</a>

                        <a href="https://wnsdomains.io/docs" style={{ marginRight: "2%", color: "black", fontSize: "85%", cursor: "pointer" }}>Docs</a>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <div className="vertcenter">
                            <a href="/app">
                                <button className="bluebutton" style={{ padding: "17.5px" }}>
                                    Launch App
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
