import React from "react";
import { Helmet } from 'react-helmet';
import LogoImg from "../../../1.resources/3.images/logo/logogradientback.png";

class Meta extends React.Component {
    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    render() {
        let host = window.location.origin;
        let url = window.location.pathname;
        let title = "";
        if (url === "/") {
            title = "Blog - WNS"
        } else {
            title = "WNS"
        }
        return (
            <Helmet>
                <title >{title}</title>
                <meta property="og:image" content="https://raw.githubusercontent.com/web3nameservice/wns-resources/main/images/logo/logogradientback.png" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Web3 Name Service (WNS)" />
                <meta property="og:description"
                    content="WNS enables mapping of your wallet address to human-readable names" />
                <meta property="og:url" content="https://wnsdomains.io/" />
                <meta property="og:site_name" content="Web3 Name Service (WNS)" />
                <meta property="og:image:type" content="image/png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@wnsdomains" />
                <meta name="twitter:creator" content="@wnsdomains" />
                <meta name="twitter:title" content="Web3 Name Service (WNS)" />
                <meta name="twitter:description"
                    content="WNS enables mapping of your wallet address to human-readable names." />
                <meta name="twitter:image" content="https://raw.githubusercontent.com/web3nameservice/wns-resources/main/images/logo/logogradientback.png" />
            </Helmet>
        );
    }
}

export default Meta;