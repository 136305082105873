import React from "react";
// import { Docs } from "../../../../1.resources/2.js/1.mainpages/docs/docs"

import OneImg from "../../../../1.resources/3.images/images/blogs/1bg.png";
import Meta from "./partials/meta";
import WriterBar from "./partials/writerbar";
class DevMain extends React.Component {

    async componentDidMount() {

    }

    render() {
        let title = "Let's begin with Web3 - WNS";
        let des = "Web3 was one of the most frequently searched terms in 2021, although it doesn’t have a formal definition, but simply put, it refers to the third generation of the internet..."
        let img = "https://raw.githubusercontent.com/web3nameservice/wns-resources/main/images/blog/1.png";
        return (
            <div>
                <Meta title={title} des={des} img={img} />
                <div>
                    <p style={{ fontSize: "80%" }}>
                        <a href="/" className="bluelink">Blog</a> / <a href="/blog/let's-begin-with-web3" className="bluelink">Welcome</a>
                    </p>
                </div>
                <div >
                    <p id="posttitle" style={{ fontSize: "250%", fontWeight: "bold" }}>Let's begin with Web3</p>
                </div>

                <br />
                <WriterBar date="March 11, 2022" />

                <div>
                    <img src={OneImg} />
                    <br /><br /><br />
                    <p id="postpara" className="docs-paragraph" style={{ textAlign: "left" }}>Web3 was one of the most frequently searched terms in 2021, although it doesn’t have a formal definition,
                        but simply put, it refers to the third generation of the internet. <span className="bluelink">Web1.0</span>, or the first generation of the internet,
                        was mostly comprised of read-only static sites; <span className="bluelink">Web2.0</span>, on the other hand, introduced the capability of
                        engaging with and generating information or material; whether it's online banking or online shopping,
                        the majority of things remain on Web2.0.

                        <br /><br />

                        So, where is  <span className="bluelink">Web3.0</span>? Web3 is still in its infancy and has yet to realize its full potential.
                        The primary term associated with Web3 is <span className="bluelink">"decenteralisation"</span>, which you could also refer to as the
                        essence of Web3. At its heart, decentralisation is a distributed, user-run network design. Information
                        is freely accessible and is not restricted to a particular company's server.



                        <br /><br />
                        Web3 also addresses the web2's key concerns, particularly privacy, data sharing, and censorship.
                        Because the Web3 design is primarily <span className="bluelink">"inclusive"</span>, one cannot prevent
                        someone from accessing a service,
                        which is mostly due to the Web3's second feature, <span className="bluelink">"privacy"</span>. Can you think of
                        a trillion-dollar organization
                        whose creator has never been seen? Most likely not. However, this is only possible in Web3 for example,
                        Satoshi Nakamoto of Bitcoin, which demonstrates the amount of anonymity offered by Web3.
                        <span className="bluelink">Cryptocurrency</span>
                        and <span className="bluelink">NFTs</span> are only a few aspects of Web3, but the majority of the apps we use will soon adopt Web3. The
                        banking industry is notorious for being one of the most conservative and slow to adopt new technologies,
                        but it has also embraced the blockchain in various aspects of security, auditing, and accounting. Social
                        media is the next major arena that will embrace Web3, and with <span className="bluelink">Twitter</span> already confirming NFTs, the ball
                        has already begun rolling.


                        <br /><br />

                        With that stated, it's critical to recognize that Web3 is more than simply a new technology; it's also about
                        a new ideology and a new identity. It's about empowering individuals with ownership, control, and accountability
                        over something. Your wallet address can hold all of your artwork, all of your information, and all of your assets
                        in a way that no other mode of identification can. And that's where domains come in; they are essentially a more
                        human-readable version of your wallet or, in programming jargon, a way to <span className="bluelink">"beautify"</span> your wallet address.


                        <br /><br />

                        <span className="bluelink">Web3 Name Service</span>, or WNS, is a domain name service that associates your wallet address with the.Web3 extension.
                        The WNS extension was chosen since it encompasses all of the features of this revolution. Web3 is not chain-specific,
                        and by that we mean not just L2, but also other chains. Web3 is not restricted to storing your coins and NFTs; it also
                        stores your data, which enables us to log in to social networking platforms using only our wallet's.Web3 name.
                        Additionally, WNS has unique features such as migration. <span className="bluelink">Migration</span> enables NFT holders of relevant collections to
                        extend their holdings and also migrate from.Web3 to that format and vice versa. In the WNS protocol, just the name
                        is registered, and the registrant receives all eligible extensions; this limits the supply and eliminates ambiguity.
                        Domains will be your one pass for all things Web3 in the future. The domain's scope is so enormous that its full
                        potential has not yet been realized.</p>

                </div>
            </div>

        )
    }
}

export default DevMain;
