import React from "react";
// import { Docs } from "../../../../1.resources/2.js/1.mainpages/docs/docs"
import BlogOne from "./blogs/1";
import BlogTwo from "./blogs/2";


class DevMain extends React.Component {

    async componentDidMount() {
        // Docs();
    }

    render() {
        let mainarea;
        let url = window.location.href;
        let urlarray = url.split("/");
        console.log(urlarray);
        if (urlarray[4] === "let's-begin-with-web3") {
            mainarea = <BlogOne />
        } else if (urlarray[4] === "how-ipfs-will-power-the-web3-email") {
            mainarea = <BlogTwo />
        } else {
            mainarea = <p>Nope</p>
        }
        return (
            <div>
                <div style={{ background: "#7b3fe4", padding: "5%" }}>
                </div>
                <div id="blogpost" style={{ marginLeft: "20%", marginRight: "20%", background: "#fff", marginTop: "-7.5%", borderRadius: "12.5px", padding: "5%", paddingTop: "3.5%" }}>
                    {mainarea}
                </div>
            </div>
        )
    }
}

export default DevMain;
