import React from "react";
import WnsLogo from "../../../../../1.resources/3.images/logo/logocircle.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WriterBar = (props) => {

    return (
        <div>
            <div style={{ display: "flex", marginTop: "-2.5%" }}>
                <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                    <div style={{ width: "10%" }} className="vertcenter">
                        <img src={WnsLogo} width="60%" />
                    </div>
                    <div style={{ width: "90%" }} className="onlyvertcenter">
                        <div>
                            <p style={{ fontSize: "80%" }}>WNS Team</p>
                            <p style={{ fontSize: "70%", color: "#606060" }}>{props.date}</p>
                        </div>
                    </div>
                </div>
                <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }} className="onlyvertcenter">
                    <FontAwesomeIcon icon={['fab', 'fa-twitter']} style={{
                        padding: "5px", color: "white", borderRadius: "50%", fontSize: "80%",
                        background: "#7b3fe4", width: "15px", height: "15px"
                    }} />
                    <FontAwesomeIcon icon={['fab', 'fa-facebook-f']} style={{
                        padding: "5px", color: "white", borderRadius: "50%", fontSize: "80%",
                        background: "#7b3fe4", width: "15px", height: "15px", marginLeft: "2.5%"
                    }} />
                </div>
            </div>
            <br />
        </div>
    )
}


export default WriterBar;
