import React from "react";
import "../../1.resources/1.css/1.mainpages/blog.css"
import "../../1.resources/1.css/1.mainpages/docs.css"
import "../../1.resources/1.css/0.global/marquee.css"


import BlogMain from "../../2.partials/1.mainpages/blogs/main";
import BlogTemplate from "../../2.partials/1.mainpages/blogs/template";
import Header from "../../2.partials/0.layout/global/header";
import Footer from "../../2.partials/1.mainpages/index/partials/blackfooter";
import MobileLogo from "../../1.resources/3.images/logo/logocircle.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MobileIndex, SidebarJS } from "../../1.resources/2.js/mobileindex";

class Index extends React.Component {
    componentDidMount() {
        MobileIndex();
        SidebarJS();
    }

    render() {
        let mainarea;
        let url = window.location.href;
        let urlarray = url.split("/");
        console.log(urlarray);
        if (urlarray.length == 4) {
            mainarea = <BlogMain />
        } else if (urlarray[3] == "blog") {
            mainarea = <BlogTemplate />
        }
        return (

            <div>
                <div id="desktop">
                    <Header />
                    {mainarea}
                    <div className="marquee">
                        <center>
                            <p style={{ fontSize: "80%" }}>#TheWeb3Revolution</p>
                        </center>
                    </div>
                    <Footer />
                </div>
                {/*-Desktop Site*/}

                {/*-Mobile Site*/}
                <div id="mobile">
                    <div style={{ display: "flex", padding: '5%', borderBottom: '1px solid #e5e5e5' }} className="mobileheader vertcenter">
                        <div style={{ width: "10%" }}>
                            <span id="openbtn" style={{ cursor: "pointer" }}>&#9776;</span>

                        </div>
                        <div style={{ width: "80%" }}>
                            <div id="mobheader" style={{}} className="vertcenter" >
                                <img src={MobileLogo} width="10%" style={{ borderRadius: '50px' }} />
                                <a href="/" style={{ color: 'black', fontWeight: 'bold', marginLeft: '4%', fontSize: "135%" }}>Web3 Name Service</a>
                            </div>
                        </div>
                        <div style={{ width: "10%" }}>

                        </div>
                    </div>

                    <div id="menusidebar">
                        <div style={{ display: "flex", padding: '3%', borderBottom: '1px solid #e5e5e5' }} className="vertcenter">
                            <div style={{ width: "10%" }}>

                            </div>
                            <div style={{ width: "80%" }}>
                                <div id="mobheader" style={{}} className="vertcenter" >
                                    <img src={MobileLogo} width="10%" style={{ borderRadius: '50px' }} />
                                    <a href="/" style={{ color: 'black', fontWeight: 'bold', marginLeft: '4%', fontSize: "135%" }}>Web3 Name Service</a>
                                </div>
                            </div>
                            <div style={{ width: "10%" }}>
                                <a id="closebtn" className="closebtn" style={{ display: "none" }}>&times;</a>

                            </div>
                        </div>
                        <br />
                        <div style={{ marginLeft: "10%", marginRight: "10%", fontSize: "100%", color: "#404040" }}>
                            <a href="https://wnsdomains.io/" style={{ color: "#404040" }}>
                                <div style={{ display: "flex", marginBottom: "8.1%" }} className="onlyvertcenter">
                                    <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                                        <FontAwesomeIcon icon={['fas', 'fa-home']} style={{ display: "none" }} />
                                        <p style={{ marginLeft: "4%" }}>Home</p>
                                    </div>
                                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-angle-right']} />
                                    </div>
                                </div>
                            </a>

                            <a href="https://wnsdomains.io/app" style={{ color: "#404040" }}>
                                <div style={{ display: "flex", marginBottom: "8.1%" }} className="onlyvertcenter">
                                    <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                                        <FontAwesomeIcon icon={['fas', 'fa-cash-register']} style={{ display: "none" }} />
                                        <p style={{ marginLeft: "4%" }}>Register</p>
                                    </div>
                                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-angle-right']} />
                                    </div>
                                </div>
                            </a>

                            <a href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd" target="_blank" style={{ color: "#404040" }}>
                                <div style={{ display: "flex", marginBottom: "8.1%" }} className="onlyvertcenter">
                                    <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                                        <FontAwesomeIcon icon={['fab', 'fa-chrome']} style={{ display: "none" }} />
                                        <p style={{ marginLeft: "4%" }}>Extension</p>
                                    </div>
                                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-angle-right']} />
                                    </div>
                                </div>
                            </a>

                            <a href="/" style={{ color: "#404040" }}>
                                <div style={{ display: "flex", marginBottom: "8.1%" }} className="onlyvertcenter">
                                    <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                                        <FontAwesomeIcon icon={['fab', 'fa-chrome']} style={{ display: "none" }} />
                                        <p style={{ marginLeft: "4%" }}>Blog</p>
                                    </div>
                                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-angle-right']} />
                                    </div>
                                </div>
                            </a>

                            <a href="https://wnsdomains.io/docs" style={{ color: "#404040" }}>
                                <div style={{ display: "flex", marginBottom: "8.1%" }} className="onlyvertcenter">
                                    <div style={{ width: "80%", display: "flex" }} className="onlyvertcenter">
                                        <FontAwesomeIcon icon={['fab', 'fa-chrome']} style={{ display: "none" }} />
                                        <p style={{ marginLeft: "4%" }}>Docs</p>
                                    </div>
                                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-angle-right']} />
                                    </div>
                                </div>
                            </a>


                        </div>
                    </div>
                </div>
                {/*-Mobile Site*/}
            </div>
        );
    }
}

export default Index;
