import React from "react";
import Logo from "../../../1.resources/3.images/logo/logocircle.png";

function HeaderLogo() {
    return <div>
        <div className="onlyvertcenter">
            <img src={Logo} style={{ borderRadius: '50px', width: "14%" }}></img>
            <p style={{ color: 'black', fontWeight: 'bold', marginLeft: '5%' }}>Web3 Name Service</p>
        </div>
    </div>

}

export default HeaderLogo;