import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import "./2.partials/0.layout/imports_css";

import Blog from "./3.views/1.mainpages/blog";
import Meta from "./2.partials/0.layout/global/meta";

library.add(fas, fab);
class App extends React.Component {
    componentDidMount() {
        document.body.style.backgroundImage = "none";

    }
    render() {
        return (
            <div>
                <Meta />
                <Blog />
            </div>
        );
    }
}

export default App;