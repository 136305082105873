import React from "react";

import { Helmet } from 'react-helmet';

const Meta = (props) => {

    let url = window.location.href;
    return (
        <div>
            <Helmet>
                <title >{props.title}</title>
                <meta property="og:image" content={props.img} />
                <meta property="og:title" content={props.title} />
                <meta property="og:description"
                    content={props.des} />
                <meta property="og:url" content={url} />
                <meta property="og:site_name" content={props.title} />


                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wnsdomains" />
                <meta name="twitter:creator" content="@wnsdomains" />
                <meta name="twitter:title" content={props.title} />
                <meta name="twitter:description"
                    content={props.des} />
                <meta name="twitter:image" content={props.img} />
            </Helmet>
        </div>
    )
}


export default Meta;
