import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../../../1.resources/1.css/0.global/footer.css"

function BlackFooter() {
    function scrollTo(el) {
        document.getElementById(el + "div").scrollIntoView();
    }
    return (
        <div>
            <div id="wnsfooter" style={{ background: "black", paddingLeft: "25%", paddingRight: "25%", paddingTop: "5%", paddingBottom: "2.5%" }}>

                <div id="lowerfooter" style={{ display: 'flex' }}>
                    <div id="footerleft" style={{ width: '50%' }}>
                        <div id="main">
                            <div style={{ display: 'flex' }}>
                                <div id="pages" style={{ width: '50%' }}>
                                    <p className="navcats" style={{ color: "white" }}>Pages</p>
                                    <a href="https://wnsdomains.io">
                                        <p className="navlinks">Home</p>
                                    </a>
                                    <a href="https://wnsdomains.io/app">
                                        <p className="navlinks">Register</p>
                                    </a>
                                    <a href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd">
                                        <p className="navlinks">Extension</p>
                                    </a>
                                    <a href="/">
                                        <p className="navlinks">Blog</p>
                                    </a>
                                    <a href="https://wnsdomains.io/docs">
                                        <p className="navlinks">Docs</p>
                                    </a>
                                </div>
                                <div id="links" style={{ width: '50%' }}>
                                    <p className="navcats" style={{ color: "white" }}>Resources</p>
                                    <a href="https://wnsdomains.io/docs">
                                        <p className="navlinks">Introduction</p>
                                    </a>
                                    <a href="https://wnsdomains.io/docs/register">
                                        <p className="navlinks">Registration</p>
                                    </a>
                                    <a href="https://wnsdomains.io/docs/primary-names">
                                        <p className="navlinks">Management</p>
                                    </a>
                                    <a href="https://wnsdomains.io/docs">
                                        <p className="navlinks">Resolution</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div id="copy">
                            <p className="navcats" style={{ color: "white" }}>Contact</p>
                            <p style={{ fontSize: '80%', color: "#808080" }} >Email: contact@wnsdomains.io</p>
                            {/* <p style={{ fontSize: '80%', color: '#808080' }}>© Web3 Name Service, 2021</p> */}
                        </div>
                    </div>
                    <div id="footerright" style={{ width: '50%' }}>
                        <div id="social">
                            <div id="join" style={{}}>
                                <p className="navcats" style={{ fontWeight: 'bold', color: "white" }}>Join the WNS community</p>
                                <div id="joinbtn" style={{ display: 'flex', marginTop: '5%' }}>
                                    <a href="https://twitter.com/wnsdomains" target="_blank" style={{ cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={['fab', 'fa-twitter']} style={{ padding: "50%", fontSize: "120%", borderRadius: "50%", background: "#7b3fe4" }} />
                                    </a>
                                </div>
                            </div>
                            <br />
                            <div id="download" style={{}}>
                                <p className="navcats" style={{ fontWeight: 'bold', color: "white", marginBottom: "2%" }}>Download the Chrome Extension</p>
                                <div id="downloadbtn" style={{ display: 'flex', marginTop: '5%' }}>
                                    <a href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd" target="_blank" style={{ cursor: "pointer" }}>
                                        <button className="" style={{
                                            display: "flex", padding: "17.5px", paddingRight: "15%",
                                            background: "#7b3fe4", color: "white", borderRadius: "50px", minWidth: "max-content"
                                        }}>
                                            <div style={{ width: "auto" }}>
                                                <FontAwesomeIcon icon={['fab', 'fa-chrome']} style={{ fontSize: "200%" }} />
                                            </div>
                                            <div style={{ width: "auto", marginLeft: "5%" }}>
                                                <p style={{ fontSize: "80%", fontWeight: "normal", marginBottom: "2.5%" }}>Download on the</p>
                                                <p style={{}}>Chrome&nbsp;Web&nbsp;Store</p>
                                            </div>

                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div >
    );
}

export default BlackFooter;
